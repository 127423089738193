@import 'src/assets/globalStyleElements.module';

.Skills {
  padding: $globalHomeContentContainerPadding;
  margin-bottom: 5vh;

  .programmingSkillsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .programmingSkillsHeader {
      font-size: $globalFontSize * 1.5;
      text-decoration: underline;
      @media only screen and (max-width: 768px) {
        font-size: $globalFontSize;

      }
    }

    .programmingSkillsRowContainer {
      border-radius: $globalBorderRadius;
      display: flex;
      flex-direction: column;

      &:hover {
        //--pulse-color: var(--pulse-color-skills);
        //@include globalPulseAnimationMixin;
      }
    }


    .programmingSkillsRow {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .programmingSkillsToggleContainer {
      display: flex;
      flex-direction: column;
      padding-top: 1rem;
      padding-bottom: 2rem;
    }

    .programmingSkillsToggleImages {
      display: flex;

      img {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .otherSkillsContainer {
    padding-top: $globalHomeContentContainerPadding;
    padding-bottom: $globalHomeContentContainerPadding;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .otherSkillsHeader {
      text-align: center;
      text-decoration: underline;
      font-size: $globalFontSize * 1.5;
      @media only screen and (max-width: 768px) {
        font-size: $globalFontSize;
      }
      padding-bottom: calc($globalHomeContentContainerPadding / 2);
    }

    .otherSkillsContent {
      display: flex;
      flex-direction: column;
    }
  }

  @mixin contentStyling {
    border-radius: $globalBorderRadius;
  }

  .darkTheme {
    @include darkThemeContent;
    @include contentStyling;
  }

  .lightTheme {
    @include lightThemeContent;
    @include contentStyling;
  }

  .colorfulTheme {
    @include colorfulThemeContent;
    @include contentStyling;
  }
}
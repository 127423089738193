@import 'src/assets/globalStyleElements.module';

.Header {
  $topPadding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;

  .headerElements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $topPadding 0 0 0;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }
  .themeButton {
    width: 100vw;
    display: flex;
    justify-content: end;
    padding-bottom: 0.2rem;
    padding-right: 1rem;
  }
}

@import 'src/assets/globalStyleElements.module';
.Tooltip {
  &:hover .tooltipContainer {
    opacity: 1;
  }
  &:hover .childrenContainer {
  }
  .tooltipContainer {
    text-align: center;
    font-size: $globalFontSize * 0.9;
    @media only screen and (max-width: 768px) {
      font-size: $globalFontSize * 0.7;
    }
    opacity: 0;
    width: fit-content;
    transition: opacity 0.55s ease-in-out;
  }
  .childrenContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

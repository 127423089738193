@import 'src/assets/globalStyleElements.module';

.Navigation {
  .navigationLinks {
    background-color: inherit;
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100vw;
  }
}

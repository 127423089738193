@import 'src/assets/globalStyleElements.module';

.FadeInTextContainer {
--colored-Block-Background: #{$globalHighlightColor};

  * {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  body,
  html {
    overflow: hidden;
  }

  .fadeInTitleContainer {
    $mainBlockWidth: 30vw !default;
    $mainBlockHeight: 5vh !default;
    $fadeInTime: 2s !default;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .fadeInAnimationContainer {
      width: 100%;
      height: auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .fadeinMainTitleContainer {
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        height: $mainBlockHeight;

        .fadeInMainTitleText {
          display: flex;
          position: relative;
          align-items: baseline;
          opacity: 0;
          font-size: ($globalFontSize * 2.2);
          animation: fadeIn $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92) both;
          -webkit-animation: fadeIn $fadeInTime
            cubic-bezier(0.74, 0.06, 0.4, 0.92) both;
          -moz-animation: fadeIn $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92)
            both;
          -o-animation: fadeIn $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92)
            both;
          -ms-animation: fadeIn $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92)
            both;
          animation-delay: $fadeInTime * 0.9;
        }

        .coloredBlockLeftToRight {
          display: flex;
          position: absolute;
          width: 0;
          height: inherit;
          background: var(--colored-Block-Background);
          animation: leftToRight $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92)
            forwards;
          animation-delay: 0s;
        }

        .coloredBlockRightToLeft {
          display: flex;
          position: absolute;
          width: 0;
          height: inherit;
          background: var(--colored-Block-Background);
          animation: rightToLeft $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92)
            forwards;
          animation-delay: 0s;
        }
      }
    }

    .fadeInSubtitleContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: ($globalFontSize * 0.3);

      animation: fadeIn $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92) both;
      -webkit-animation: fadeIn $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92)
        both;
      -moz-animation: fadeIn $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92)
        both;
      -o-animation: fadeIn $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92) both;
      -ms-animation: fadeIn $fadeInTime cubic-bezier(0.74, 0.06, 0.4, 0.92) both;
      animation-delay: 1s;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
        right: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes leftToRight {
      0% {
        width: 0;
        left: 0;
      }
      50% {
        width: 100%;
        left: 0;
      }
      100% {
        width: 0;
        left: 100%;
      }
    }

    @keyframes rightToLeft {
      0% {
        width: 0;
        right: 0;
      }
      50% {
        width: 100%;
        right: 0;
      }
      100% {
        width: 0;
        right: 100%;
      }
    }
  }

  .darkTheme {
    --colored-Block-Background: #{$globalHighlightColor};
  }

  .lightTheme {
    --colored-Block-Background: #{$globalHighlightColor};
  }

  .colorfulTheme {
    --colored-Block-Background: #{$globalHighlightColor};
  }
}

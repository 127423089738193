$globalFontFamily: 'Courier New', monospace;
$globalFontSize: 1.2rem;

$globalBorderRadius: 1rem;
$globalHomeContentContainerPadding: 2.5rem;

$globalDarkColor: #212121;
$globalLightColor: #fafafa;
$globalUnderlayRGBAColorDark: rgba(255, 255, 255, 0.05);
$globalUnderlayRGBAColorLight: rgba(208, 209, 255, 0.5);

$globalHighlightColor: #f6a3a0;
$globalHighlightColorDark: #fafafa;
$globalHighlightColorLight: #454555;


$colorsReact: #61dafb;
$colorsKotlin: #7f52ff;
$colorsReactRgba: rgba(97, 218, 251, 0.2);

$gradientColor1: #845ec2;
$gradientColor1: #d65db1;
$gradientColor1: #ff9671;
$gradientColor1: #ffc75f;
$gradientColor1: #f9f871;

@mixin darkTheme {
  font-family: $globalFontFamily;
  font-size: $globalFontSize;
  background-color: $globalDarkColor;
  color: $globalLightColor;
  a {
    color: white;
  }
}

@mixin lightTheme {
  font-family: $globalFontFamily;
  font-size: $globalFontSize;
  background-color: $globalLightColor;
  color: $globalDarkColor;
  a {
    color: $globalDarkColor;
  }
}

@mixin colorfulTheme {
  font-family: $globalFontFamily;
  font-size: $globalFontSize;
  background-color: #91f88b;
  color: $globalDarkColor;
  a {
    color: $globalDarkColor;
  }
}

@mixin darkThemeContent {
  padding: inherit;
  border-radius: inherit;
  background-color: $globalUnderlayRGBAColorDark;
}

@mixin lightThemeContent {
  padding: inherit;
  border-radius: inherit;
  background-color: $globalUnderlayRGBAColorLight;
}

@mixin colorfulThemeContent {
  padding: inherit;
  border-radius: inherit;
  background-color: $globalUnderlayRGBAColorLight;
}

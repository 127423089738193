@import 'src/assets/globalStyleElements.module';

.CollapsibleSection {
  padding: 1rem;

  @mixin sectionHeader {
    border-radius: $globalBorderRadius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .collapsibleSectionHeaderDark {
    @include sectionHeader;
    img {
      filter: invert(1);
    }
  }

  .collapsibleSectionHeaderLight {
    @include sectionHeader;
  }

  .collapsibleSectionHeaderColorful {
    @include sectionHeader;
  }

  .headerImage {
    width: 3rem;
    height: 3rem;
    padding-bottom: 0.5rem;
  }

  .sectionContent {
    font-size: $globalFontSize;
    text-align: center;
    white-space: pre-line;
    @media only screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .darkTheme {
    @include darkThemeContent;
  }

  .lightTheme {
    @include lightThemeContent;
  }

  .colorfulTheme {
    @include colorfulThemeContent;
  }
}


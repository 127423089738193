@import 'src/assets/globalStyleElements.module';

.ProfilePicture {
  --pulse-color-profile: #845ec2;

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contentContainer img {
    border-radius: 50rem;
    min-height: 12rem;
    min-width: 12rem;
    max-height: 12rem;
    max-width: 12rem;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
  }


  .textContainerName {
    text-align: center;
    font-size: $globalFontSize * 2.5;
  }

  .textContainerJobDescription {
    padding-top: 0.5rem;
    font-size: $globalFontSize * 1.2;
  }
  .textContainerBasedIn {
    padding-top: 0.5rem;
    font-size: $globalFontSize * 0.8;
  }

  @mixin contentStyling {
    border-radius: $globalBorderRadius;
  }

  .darkTheme {
    @include contentStyling;
  }

  .lightTheme {

    @include contentStyling;
  }

  .colorfulTheme {
    @include colorfulThemeContent;
    @include contentStyling;
  }
}

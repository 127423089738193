@import 'src/assets/globalStyleElements.module';

.ToggleButton {
  width: fit-content;
  border-radius: $globalBorderRadius;
  --experience-animation-color: rgba(255, 111, 105, 0.2);
  --highlight-color: $globalHighlightColor;

  .checkbox {
    display: none;

    &:checked + .toggleStateIndicator {
      background-color: var(--experience-animation-color);
    }
  }

  .toggleStateIndicator {
    display: flex;
    width: max-content;
    border: solid transparent;
    cursor: pointer;
    padding: 0.3rem;
    border-radius: $globalBorderRadius;
    transition: background-color 0.55s;

    &:hover {
      border: solid var(--highlight-color);
    }
  }

  .darkTheme {
    @include darkThemeContent;
    --experience-animation-color: rgba(131, 133, 138, 0.2);;
    --highlight-color: #{$globalHighlightColorDark};
    img {
      filter: invert(1);
    }
  }

  .lightTheme {
    @include lightThemeContent;
    --experience-animation-color: rgba(105, 140, 255, 0.3);
    --highlight-color: #{$globalHighlightColorLight};
  }

  .colorfulTheme {
    @include colorfulThemeContent;
    --experience-animation-color: rgba(105, 140, 255, 0.3);
    --highlight-color: #{$globalHighlightColorLight};
  }
}

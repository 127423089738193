@import 'src/assets/globalStyleElements.module';

.Projects {
  @mixin contentStyling {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: $globalBorderRadius;
  }

  .content {
    padding: $globalHomeContentContainerPadding;
    margin-bottom: 5vh;
  }

  .projectsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .projectContent {
    width: 100%;
    @include contentStyling;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
    .projectTitle {
      text-decoration: underline;
      font-size: $globalFontSize * 1.5;
      @media only screen and (max-width: 768px) {
        font-size: $globalFontSize;
      }
    }
    .textSection {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70%;
      font-size: $globalFontSize;
      text-align: center;
      white-space: pre-line;
      @media only screen and (max-width: 768px) {
        font-size: 1rem;
      }
      padding: $globalHomeContentContainerPadding;
    }
    .skillSection {
      display: inherit;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .skillSectionHeader {
      }
      .skillContainer {
        border-radius: $globalBorderRadius;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        //transform: scale(0.8);
      }
    }

    .linkSection {
      display: inherit;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .linkSectionHeader {
      }
      .linkContainer {
        &:hover {
          // --pulse-color: #ff6f69;
          // @include globalPulseAnimationMixin;
        }
        border-radius: $globalBorderRadius;
        display: flex;
        flex-direction: row;
        //transform: scale(0.8);
      }
    }
  }

  .darkTheme {
    @include darkThemeContent;
    @include contentStyling;
  }

  .lightTheme {
    @include lightThemeContent;
    @include contentStyling;
  }

  .colorfulTheme {
    @include colorfulThemeContent;
    @include contentStyling;
  }
}

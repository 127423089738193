@import 'src/assets/globalStyleElements.module';

.About {

  .content {
    padding: $globalHomeContentContainerPadding;

    .aboutMe {
      border-radius: 50vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }


    .contactContainer {
      margin-top: $globalHomeContentContainerPadding;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .contactHeader {
        font-size: calc($globalFontSize);
      }

      .contactLinksRow {
        display: inherit;
        flex-direction: row;
        margin: 2vh;
      }

      .contactLinks {
        border-radius: $globalBorderRadius;
        display: flex;
      }
    }

    @mixin contentStyling {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: $globalBorderRadius;
    }

    .darkTheme {
      @include darkThemeContent;
      @include contentStyling;
    }

    .lightTheme {
      @include lightThemeContent;
      @include contentStyling;
    }

    .colorfulTheme {
      @include colorfulThemeContent;
      @include contentStyling;
    }
  }
}

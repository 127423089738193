@import 'src/assets/globalStyleElements.module';

.NavigationLinks {
  --highlight-color : $globalHighlightColor;
  display: flex;
 justify-content: center;
  flex: 1;

  a {
    text-decoration: none;
    border-radius: $globalBorderRadius;
  }

  @mixin generalLinkStyling {
    border-radius: $globalBorderRadius;
    border: solid transparent;
    margin: 1rem;
    padding: 0.5rem;
    text-transform: uppercase;
    font-size: clamp($globalFontSize, 3vw, $globalFontSize *2.4);
  }


  .inactiveLinkDarkTheme {
    @include generalLinkStyling;
    --highlight-color: #{$globalHighlightColorDark};
    &:hover {
    border: solid var(--highlight-color);
  }
  }

  .inactiveLinkLightTheme {
    --highlight-color: #{$globalDarkColor};
    @include generalLinkStyling;
    &:hover {
      border: solid var(--highlight-color);
    }
  }

  .inactiveLinkColorfulTheme {
    --highlight-color: #{$globalHighlightColorLight};
    @include generalLinkStyling;
    &:hover {
      border: solid var(--highlight-color);
    }
  }


  .activeLinkDarkTheme {
    @include generalLinkStyling;
    border: solid $globalLightColor;
  }

  .activeLinkLightTheme {
    @include generalLinkStyling;
    border: solid $globalDarkColor;
  }

  .activeLinkColorfulTheme {
    @include generalLinkStyling;
    border: solid $globalDarkColor;
  }
}

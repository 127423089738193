@import 'src/assets/globalStyleElements.module';

.Home {
  overflow: hidden;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .contentContainer {
    height: 100vh;
    box-sizing: border-box;
    max-height: 80vh;
    overflow: auto;
  }

  .darkTheme {
    @include darkTheme;
  }
  .lightTheme {
    @include lightTheme;
  }
  .colorfulTheme {
    @include colorfulTheme;
  }
}

@import 'src/assets/globalStyleElements.module';

.darkTheme {
  @include darkTheme;
}

.lightTheme {
  @include lightTheme;
}

.colorfulTheme {
  @include colorfulTheme;
}

.darkThemeContent {
  @include darkThemeContent;
}

.lightThemeContent {
  @include lightThemeContent;
}

.colorfulThemeContent {
  @include colorfulThemeContent;
}

@import 'src/assets/globalStyleElements.module';

.SkillBanner {
  padding: 0.5rem;
  --experience-animation-color: rgba(255, 111, 105, 0.2);

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageContainer img {
    height: 2.7rem;
    width: 2.7rem;
    @media only screen and (max-width: 768px) {
      width: 2rem;
      height: 2rem;
    }
    transition: transform 0.15s ease-in-out;
    position: absolute;
  }

  .textContainer {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0.4rem;
    text-align: center;
    font-size: $globalFontSize * 0.8;
    border-radius: 50vw;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  @mixin contentStyling {
    width: 8rem;
    height: 4rem;
    &:hover img {
      transform: translate(0%, -20%);
    }
    &:hover .textContainer {
      opacity: 1;
    }
    @media only screen and (max-width: 768px) {
      width: 3rem;
      height: 2rem;
      &:hover img {
        transform: none;
      }
      &:hover .textContainer {
        opacity: 0;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border: solid transparent;
    border-radius: $globalBorderRadius * 10;

  }

  @mixin experienceIndicatorMixin {
    overflow: hidden;
    background-color: #ddd;
    position: relative;
  }

  @mixin experienceIndicatorAnimationContainerMixin {
    content: '';
    width: 100%;
    background-color: var(--experience-animation-color);
    position: absolute;
    bottom: 0;
    animation: fadeIn 1.1s ease-in-out both,
    bottomToTop 0.55s cubic-bezier(0.74, 0.06, 0.4, 0.92) both;
  }

  .beginner {
    @include experienceIndicatorMixin;
  }

  .beginner::before {
    height: 20%;
    @include experienceIndicatorAnimationContainerMixin;
  }

  .moderate {
    @include experienceIndicatorMixin;
  }

  .moderate::before {
    height: 60%;
    @include experienceIndicatorAnimationContainerMixin;
  }

  .experienced {
    @include experienceIndicatorMixin;
  }

  .experienced::before {
    height: 90%;
    @include experienceIndicatorAnimationContainerMixin;
  }

  .darkTheme {
    @include darkThemeContent;
    --experience-animation-color: rgba(131, 133, 138, 0.2);
    @include contentStyling;
  }

  .lightTheme {
    @include lightThemeContent;
    --experience-animation-color: rgba(105, 140, 255, 0.3);
    @include contentStyling;
  }

  .colorfulTheme {
    @include colorfulThemeContent;
    @include contentStyling;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    right: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bottomToTop {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

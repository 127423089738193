@import 'src/assets/globalStyleElements.module';

.LinkBanner {
  padding: 0.5vw;
  --highlight-color : $globalHighlightColor;


  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 4rem;
    @media only screen and (max-width: 768px) {
      width: 4rem;
      height: 2rem;
    }
    position: relative;
    border-radius: $globalBorderRadius * 10;

  }

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.7rem;
    width: 2.7rem;
    @media only screen and (max-width: 768px) {
      width: 2rem;
      height: 2rem;
    }
  }



  .textContainer {
    position: absolute;
    text-align: center;
    bottom: 0;
    opacity: 0;
    border-radius: 50vw;
    font-size: $globalFontSize * 0.8;
    transition: opacity 0.1s ease-in-out;
  }

  @mixin contentStyling {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: $globalBorderRadius * 10;
    @media only screen and (max-width: 768px) {
      border: solid var(--highlight-color);
    }

    @media only screen and (min-width: 768px) {
      border: solid transparent;
      &:hover {
        border: solid var(--highlight-color);
      }
      &:hover .textContainer {
        opacity: 1;
      }
      &:hover img {
        transform: translate(0%, -20%);
      }

    }
  }

  .darkTheme {
    @include darkThemeContent;
    @include contentStyling;
    --highlight-color: #{$globalHighlightColorDark};
    cursor: pointer;

    .imageContainer {
      filter: invert(1);
    }

  }

  .lightTheme {
    @include lightThemeContent;
    @include contentStyling;
    --highlight-color: #{$globalHighlightColorLight};
    cursor: pointer;
  }

  .colorfulTheme {
    @include colorfulThemeContent;
    @include contentStyling;
    --highlight-color: #{$globalHighlightColorDark};
    cursor: pointer;
  }
}

@import 'src/assets/globalStyleElements.module';

.ThemeMenu {
  --highlight-color : $globalHighlightColor;
  --inverted-highlight-color : $globalHighlightColor;


  border-radius: $globalBorderRadius;
  width: 5rem;
  @mixin themeMenuButtonStyling {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border: solid transparent;
    border-radius: $globalBorderRadius;
    padding: 0.35vw !important;
    img {
      height: 1.5rem;
    }
    &:hover {
      border: solid var(--highlight-color);
    }
  }

  .themeMenuButtonDarkTheme {
    @include themeMenuButtonStyling;
    --highlight-color: #{$globalHighlightColorDark};
    --inverted-highlight-color: #{$globalHighlightColorLight};
    img {
      filter: invert(1);
    }
  }

  .themeMenuButtonLightTheme {
    @include themeMenuButtonStyling;

    --highlight-color: #{$globalHighlightColorLight};
  }

  .themeMenuButtonColorfulTheme {
    @include colorfulThemeContent;
    --highlight-color: #{$globalHighlightColorLight};
  }

  .themeMenuElements {
    position: absolute;
    z-index: 1000;
    width: inherit;
    height: inherit;

    @mixin themeMenuElementStyling {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.8vh;
      cursor: pointer;
      width: inherit;
      height: inherit;
      border: solid transparent;
      border-radius: $globalBorderRadius;
      &:hover {
        border: solid var(--inverted-highlight-color);
      }
    }

    .elementDarkTheme {
      @include themeMenuElementStyling;
      background: $globalLightColor;
      color: $globalDarkColor;
      --inverted-highlight-color:  #{$globalHighlightColor};
    }

    .elementLightTheme {
      @include themeMenuElementStyling;
      background: $globalDarkColor;
      color: $globalLightColor;
      --inverted-highlight-color: #{$globalHighlightColor};
    }

    .elementColorfulTheme {
      @include themeMenuElementStyling;
      background: $globalDarkColor;
      color: $globalLightColor;
    }
  }
}
